import React, { Component } from "react";
import { applyVueInReact } from "vuereact-combined";
import Payment from "../../components/payment/Payment";
import LoadingSpinner from "@/components/LoadingSpinner";
import axios from "@/axios";
class CreditCard extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);
  constructor() {
    super();
    this.state = {
      loading: true,
      message: "",
      issuerList: [],
      selectedIssuer: {},
      cardNumber: "",
      cardHolder: "",
      billAmount: 0,
      totalAmount: 0,
      serviceCharge: 0,
    };
  }
  componentDidMount() {
    axios.get("/api/v2/services/creditcard/issuerlist").then((data) => {
      this.setState({
        issuerList: data.data.response.IssuerList,
        loading: false,
      });
    });
  }

  handleIssuerChange = (event) => {
    const data = event.target.value ? JSON.parse(event.target.value) : "";
    this.setState({ selectedIssuer: data });
  };

  handleChange = (event) => {
    if (event.target.name === "cardNumber") {
      const allowedText = /^[0-9]*$/;
      if (!allowedText.test(event.target.value)) {
        return;
      }
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  creditCardCharge = (event) => {
    event.preventDefault();
    if (Object.keys(this.state.selectedIssuer).length === 0) {
      this.setState({ message: "Please select the credit card issuer." });
      return;
    }
    if (this.state.cardNumber.length < 16) {
      this.setState({ message: "Card Number should be Numeric 16 Digit." });
      return;
    }
    if (this.state.billAmount < 100 || this.state.billAmount > 1000000) {
      this.setState({ message: "Amount should be between 100 to 1000000." });
      return;
    }
    this.setState({
      billAmount: parseFloat(this.state.billAmount).toFixed(2),
      loading: true,
    });

    axios
      .post("/api/v2/services/creditcard/charge", {
        IssuerCode: this.state.selectedIssuer.code,
        Amount: this.state.billAmount,
      })
      .then((data) => {
        this.setState(
          {
            serviceCharge: data.data.response.SCharge,
          },
          () => {
            const totalAmount =
              parseFloat(this.state.serviceCharge) +
              parseFloat(this.state.billAmount);
            this.setState({
              totalAmount: totalAmount.toFixed(2),
              loading: false,
              message: "",
            });
          }
        );
      })
      .catch(() => {
        this.setState({ message: "Something went wrong.", loading: false });
      });
  };

  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            <h4>Credit Card Payment</h4>
            {this.state.message && (
              <div className="form-group">
                <div className="show-message">{this.state.message}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                {this.state.serviceCharge ? (
                  <div>
                    <div className="mt-4">
                      <div className="font-weight-bold"> Card Issuer</div>
                      <div>{this.state.selectedIssuer.name}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold"> Card Number</div>
                      <div>{this.state.cardNumber}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold"> Card Holder Name</div>
                      <div>{this.state.cardHolder}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold"> Payment Amount</div>
                      <div>{this.state.billAmount}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold"> Service Charge</div>
                      <div>{this.state.serviceCharge}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold"> Total Amount</div>
                      <div>{this.state.totalAmount}</div>
                    </div>
                    {/* Payment Division */}
                    <div className="fade-animation">
                      <Payment
                        paymentUri="api/v4/creditcard/payment"
                        paymentPayload={{
                          Amount: this.state.billAmount,
                          CreditCardNumber: this.state.cardNumber,
                          IssuerCode: this.state.selectedIssuer.code,
                          CardHolderName: this.state.cardHolder,
                          ServiceCharge: this.state.serviceCharge,
                        }}
                        setMessage={(message) => {
                          this.setState({ message });
                        }}
                        paymentPreviewDetail={{
                          "Customer Name": this.state.cardHolder,
                          "Total Amount to Pay":
                            "Rs. " + this.state.totalAmount,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <form onSubmit={this.creditCardCharge}>
                    <div className="form-group">
                      <select
                        className="custom-select"
                        name="creditCardIssuer"
                        onChange={this.handleIssuerChange}
                        required
                      >
                        <option value="">-- SELECT CARD ISSUER --</option>
                        {this.state.issuerList.map((data) => {
                          return (
                            <option
                              key={data.code}
                              value={JSON.stringify(data)}
                            >
                              {data.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Card Number</label>
                      <input
                        className="form-control"
                        type="text"
                        value={this.state.cardNumber}
                        name="cardNumber"
                        maxLength="16"
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Card Holder Name</label>
                      <input
                        className="form-control"
                        type="text"
                        value={this.state.cardHolder}
                        name="cardHolder"
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Bill Amount</label>
                      <input
                        className="form-control"
                        type="number"
                        value={this.state.billAmount}
                        name="billAmount"
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary mt-3">
                        Submit
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default CreditCard;
